
import { React, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS CSS file
import HomePage from './pages/HomePage';
function App() {

  useEffect(() => {
    AOS.init({
      // Global settings:
      duration: 800, // values from 0 to 3000, with step 50ms
      once: true, // whether animation should happen only once - while scrolling down
      // other options can be found in AOS documentation
    });
  }, []);
  return (
    <div className="App">
      <HomePage />
    </div>
  );
}

export default App;
