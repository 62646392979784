
import React from "react"
const Study = () => {
    return (
        <div dir="rtl" className="py-20 px-8 bg-brown">
            <h1 className="text-5xl md:text-6xl text-center text-white font-extrabold mt-2">ההכשרה שלי</h1>
            <ul className="md:text-2xl list-disc max-w-[800px] px-4 mx-auto mt-8 text-white">
                <li className="mt-2">
                    ייעוץ זוגי
                </li>
                <li className="mt-2">
                    תואר M.A בייעוץ זוגי ומשפחתי. המכללה למנהל

                </li>
                <li className="mt-2">

                    תואר B.ed בחינוך. מכללת לוינסקי
                </li>

                <li className="mt-2">

                    החוויה הדיאלוגית - בר אילן
                </li>
                <li className="mt-2">

                    הנחיית קבוצות- מכון אדלר
                </li>
                <li className="mt-2">

                    קורסים והשתלמויות בתחומים - מינדפולנס, הדרכת הורים, פיתוח מודעות עצמית, נמרולוגיה, קלפים השלכתיים.
                </li>
            </ul>


        </div>
    )
}

export default Study