import React from 'react';

const InfoCard = ({ icon: Icon, title, description }) => (
    <div data-aos="fade-down"
        data-aos-easing="ease-in-out">
        <div className='group bg-card cursor-default p-8 shadow-2xl min-h-64 flex flex-col border-white border-2 justify-center items-center bg-gradient-to-r from-white to-gray-200 rounded-2xl relative hover:scale-105 duration-300'>
            <div className='mb-2'>
                {/* Assuming Icon is a component that accepts size and className, if not you might need to adjust this */}
                <Icon size={80} className="text-[#e8b3a3] mx-auto m-4" />
                <h1 className='text-black font-bold text-2xl text-center'>{title}</h1>
            </div>
            <p dir='rtl' className='text-center text-xl text-black'>{description}</p>
        </div>
    </div>
);

export default InfoCard;
