
import React from 'react'
// import Guys from '../images/guys.jpg'
import Guy from '../images/guy.jpeg'
import Guy1 from '../images/guy1.jpeg'
import More1 from '../images/more01.jpeg'


import { IoCall, IoLogoInstagram, IoPhoneLandscape } from 'react-icons/io5';
import { IoLogoWhatsapp } from 'react-icons/io';
import { MdOutlineDone } from 'react-icons/md';
export default function More() {
    return (
        <div id='aboutus' className="p-4 bg-gray-100 dotbg rounded-t-3xl py-8 pb-12 ">


            <h1 dir="rtl" className="text-5xl md:text-6xl text-center text-[#333] font-extrabold mt-2">קצת עליי
            </h1>
            <div class="heart-container mx-auto my-4" title="Like">
                <input type="checkbox" class="checkbox" id="Give-It-An-Id" />
                <div class="svg-container">
                    <svg viewBox="0 0 24 24" class="svg-outline" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Zm-3.585,18.4a2.973,2.973,0,0,1-3.83,0C4.947,16.006,2,11.87,2,8.967a4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,11,8.967a1,1,0,0,0,2,0,4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,22,8.967C22,11.87,19.053,16.006,13.915,20.313Z">
                        </path>
                    </svg>
                    <svg viewBox="0 0 24 24" class="svg-filled" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Z">
                        </path>
                    </svg>
                    <svg class="svg-celebrate" width="100" height="100" xmlns="http://www.w3.org/2000/svg">
                        <polygon points="10,10 20,20"></polygon>
                        <polygon points="10,50 20,50"></polygon>
                        <polygon points="20,80 30,70"></polygon>
                        <polygon points="90,10 80,20"></polygon>
                        <polygon points="90,50 80,50"></polygon>
                        <polygon points="80,80 70,70"></polygon>
                    </svg>
                </div>
            </div>

            <img alt='image' src={Guy} className='md:max-w-[500px] border-8 border-white shadow-2xl object-contain hover:scale-105 duration-300 mx-auto m-4 mt-8 rounded-3xl' />

            <div className="mt-4 flex flex-col md:flex-row-reverse max-w-[1000px] mx-auto bg-gradient-to-tr from-primary to-secondary border-white shadow-2xl text-white border-4 rounded-3xl py-4">
                {/* <Image className='md:max-w-[500px] object-contain hover:scale-105 duration-300 mx-auto m-4 rounded-3xl' /> */}
                <div dir='rtl' className='px-4  m-4'>
                    <h1 className=' text-center md:text-right  font-extrabold text-3xl md:text-5xl mt-4'>
                        מי אני?
                    </h1>


                    <p className="md:text-2xl mx-auto text-right mt-2 ">
                        בהכשרתי אני יועצת זוגית ומשפחתית שמגיעה מעולם החינוך ועבודה עם הורים וילדים.
                    </p>

                    <p className="md:text-2xl mx-auto text-right mt-2 ">
                        לייעוץ הזוגי הגעתי מתוך מסע חיי, חוויות חיים ואתגרים עימם התמודדתי שהביאו אותי ללמידה והתפתחות אישית ומקצועית ולהבנה שאכן אפשר לחיות בזוגיות טובה ואוהבת יותר.                    </p>

                    <p className="md:text-2xl mx-auto text-right mt-2 ">
                        הלמידה המשמעותית ביותר היא לגלות שהכל מתחיל מתוך צמיחה אישית ותחושת ביטחון וערך גבוה שמשפיעים על הבחירה הזוגית ועל הזוגיות עצמה.                     </p>

                    <p className="md:text-2xl mx-auto text-right mt-2 ">
                        לאורך השנים למדתי מיומנויות טיפול שונות ופיתחתי סל כלים שבאמצעותו אני עוזרת לזוגות ולנשים לחיות בזוגיות טובה ואוהבת.                    </p>


                </div>
            </div>

            <div className="mt-12 flex flex-col md:flex-row-reverse max-w-[1000px] bg-white py-8 rounded-3xl shadow-2xl mx-auto">
                <img alt='image' src={More1} className='md:max-w-[500px] border-8 border-white rounded-3xl shadow-2xl hover:scale-105 duration-300 mx-auto m-4 ' />
                <div dir='rtl' className='px-4  m-4 md:m-8'>
                    <h1 className='text-primary text-center md:text-right font-extrabold text-3xl md:text-4xl mt-4'>
                        למי מתאים ייעוץ זוגי?

                    </h1>
                    <ul className='mx-auto  sm:text-right list-disc text-black mt-4'>
                        <li className='mt-4 flex items-center justify-start md:text-xl'>
                            <MdOutlineDone className="w-5 h-5 mr-2 ml-2 md-icon" />
                            לזוגות שחוו משבר אמון ובגידה.
                        </li>
                        <li className='mt-4 flex items-center justify-start  md:text-xl'>
                            <MdOutlineDone className="w-5 h-5 mr-2 ml-2 md-icon" />
                            לזוגות שיש ביניהם ריחוק ותחושת בדידות גדולה.
                        </li>
                        <li className='mt-4 flex items-center justify-start  md:text-xl'>
                            <MdOutlineDone className="w-5 h-5 mr-2 ml-2 md-icon" />
                            לזוגות שלא נהנים כבר להיות ולבלות יחד.
                        </li>
                        <li className='mt-4 flex items-center justify-start  md:text-xl'>
                            <MdOutlineDone className="w-5 h-5 mr-2 ml-2 md-icon" />
                            לזוגות שרבים על כל דבר.
                        </li>
                        <li className='mt-4 flex items-center justify-start  md:text-xl'>
                            <MdOutlineDone className="w-5 h-5 mr-2 ml-2 md-icon" />
                            לזוגות שיש ביניהם מאבקי כוח שלא נגמרים ומתישים אותם.
                        </li>
                        <li className='mt-4 flex items-center justify-start md:text-xl'>
                            <MdOutlineDone className="w-5 h-5 mr-2 ml-2 md-icon" />
                            לזוגות שאיבדו את החברות והביחד.
                        </li>
                    </ul>


                    <p dir='rtl' className='text-center md:text-right font-bold  md:text-xl text-black mt-4'>
                        במפגשים האישיים בקליניקה נעבוד על פיתוח תחושת ערך, ביטחון עצמי, גבולות ומודל זוגי מוצלח לחיים.
                    </p>

                </div>
            </div>



            {/* <div className="flex justify-center mt-4">
                <a href="" className="bg-gradient-to-tr text-center flex justify-center items-center from-[#d0b069] m-2 to-[#a78334] px-10 py-4 text-lg font-extrabold rounded-full hover:scale-95 duration-300">
                    <IoCall className='text-4xl mr-4' />
                    <span className='text-xl md:text-2xl'>חייגו אליי לשיחת ייעוץ</span>
                </a>
            </div> */}

            {/* <div className="mt-12 flex flex-col md:flex-row-reverse max-w-[1000px] bg-white py-8  rounded-3xl shadow-2xl mx-auto">
                <img alt='image' src={Guys} className='md:max-w-[500px] border-8 border-white shadow-2xl object-contain hover:scale-105 duration-300 mx-auto m-4 rounded-3xl' />
                <div dir='rtl' className='px-4  m-4 md:m-8'>
                    <h1 data-aos="fade-down"
                        data-aos-easing="ease-in-out" className='text-primary  md:text-right  font-extrabold text-3xl md:text-4xl mt-4'>
                        למי מתאים ייעוץ אישי?

                    </h1>

                    <ul className='mx-auto list-disc text-right md:text-right  md:text-xl text-black mt-4'>
                        <li className='mt-4'>
                            למי שזקוקה לשיפור היחסים עם עצמה.
                        </li>

                        <li className='mt-4'>
                            מי שזקוקה לעלות את תחושת ביטחון העצמי והערך שלה.
                        </li>

                        <li className='mt-4'>
                            למי שלא מצליחה ליצור זוגיות ארוכה ואוהבת.
                        </li>

                        <li className='mt-4'>
                            למי שנמצאת בזוגיות ומרגישה תקועה.
                        </li>

                        <li className='mt-4'>
                            למי שרוצה לעשות שינוי בחיים ולא יודעת איך.
                        </li>

                    </ul>

                </div>
            </div> */}

            <div className="flex justify-center mt-12 text-white">
                <a href="https://wa.me/972509933553" className="bg-brown text-center flex justify-center items-center from-primary m-2 to-secondary px-10 py-4 text-lg font-extrabold rounded-full hover:scale-105 duration-300">
                    <IoLogoWhatsapp className='text-4xl mr-4' />
                    <span className='text-xl md:text-2xl'>שלחו לי הודעת ווצאפ</span>
                </a>
            </div>


        </div>
    );
}
