import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Pagination, Navigation, EffectCoverflow, Autoplay } from 'swiper/modules';
import { IoIosArrowDroprightCircle, IoIosArrowDropleftCircle } from "react-icons/io";
const ClientsSwiper = ({ testimonials }) => {
    return (
        <>
            <div className='px-4 py-20 rounded-2xl dotbg'>
                <div  className='relative swiper2 bg-white rounded-3xl py-8 shadow-2xl max-w-[1000px] mx-auto'>
                    <h1 className='text-center text-3xl my-4 sm:text-5xl font-bold'>לקוחות ממליצים</h1>
                    <div className='border-[1px] my-4 mx-auto border-black w-[50%] sm:max-w-[400px]'  ></div>
                    <Swiper
                        effect={'coverflow'}
                        grabCursor={true}
                        autoplay={{ delay: 800, disableOnInteraction: true }}
                        centeredSlides={true}
                        navigation={{ nextEl: '.arrow-right2', prevEl: '.arrow-left2' }}
                        loop={true}
                        speed={1000}
                        spaceBetween={20}
                        pagination={{
                            clickable: true,
                        }}
                        slidesPerView={'auto'}
                        coverflowEffect={{
                            rotate: 50,
                            stretch: 0,
                            depth: 100,
                            modifier: 1,
                            slideShadows: false,
                        }}
                        modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
                        className='w-[100%] h-[350px]'
                    >
                        {testimonials && testimonials.map((testimonial, index) => (
                            <SwiperSlide key={index} className='w-[100%]  '>
                                <div dir='rtl' className='max-w-[250px] sm:max-w-[450px] items-center mx-auto mt-[20px]'>
                                    <p className={`${'text-clientsSwiper_textColor font-medium mx-auto text-center'} ${testimonial.text.length > 200 ? 'text-xs sm:text-sm' : ''}`}>
                                        "{testimonial.text}"
                                    </p>
                                    <p className="text-clientsSwiper_authorColor font-bold mt-2 text-sm text-center">{testimonial.author}</p>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <button className='arrow-left2 arrow m-2 '>
                        <IoIosArrowDropleftCircle size={35} className='text-clientsSwiper_iconColor rounded-full hover:opacity-80' />
                    </button>
                    <button className='arrow-right2 arrow m-2'>
                        <IoIosArrowDroprightCircle size={35} className='text-clientsSwiper_iconColor rounded-full hover:opacity-80' />
                    </button>
                </div>
            </div>
        </>
    );
};

export default ClientsSwiper