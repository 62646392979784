import { FaWhatsapp } from "react-icons/fa";
import { IoIosCall } from "react-icons/io";
import { IoLogoFacebook, IoLogoTiktok } from "react-icons/io5";





export default function Footer() {
    return (
        <div className=' mx-auto text-center py-6  text-black'>
            {/* <a href="https://wa.me/972538250965?text=%D7%94%D7%99%D7%99%20%D7%90%D7%A9%D7%9E%D7%97%20%D7%9C%D7%A4%D7%A8%D7%98%D7%99%D7%9D%20%D7%91%D7%A0%D7%95%D7%92%D7%A2%20%D7%9C%D7%91%D7%A0%D7%99%D7%99%D7%AA%20%D7%90%D7%AA%D7%A8" dir='rtl' className="mt-8 text-base    leading-6 text-center text-mainColor ">
                דף זה נבנה על ידי Idan Shlomo | דפי נחיתה ממירים
            </a> */}

            <h1 dir="rtl" className="text-2xl font-bold text-primary">לשיחת ייעוץ חינם</h1>

            <div className="flex justify-center my-8 space-x-6">
                {/* <a href="#" className="text-secondaryColor hover:text-gray-500">
                            <RiInstagramFill size={30} />
                            IG: digicard.il
                        </a>
                        <a href="#" className="text-secondaryColor hover:text-gray-500">
                            <FaFacebook size={30} />
                        </a> */}


                <a href="tel:+972509933553" className="text-white bg-primary h-12 w-12 rounded-full flex items-center justify-center shadow-xl shadow-shineColor2 hover:shadow-shineColor3 duration-300">
                    <IoIosCall size={30} />
                </a>



                <a href="https://wa.me/972509933553" className="text-white bg-primary h-12 w-12 rounded-full flex items-center justify-center shadow-xl shadow-shineColor2 hover:shadow-shineColor3 duration-300" target="_blank" rel="noopener noreferrer">
                    <FaWhatsapp size={30} />
                </a>

                <a href="https://www.facebook.com/profile.php?id=100085450906939" className="text-white bg-primary h-12 w-12 rounded-full flex items-center justify-center shadow-xl shadow-shineColor2 hover:shadow-shineColor3 duration-300">
                    <IoLogoFacebook size={30} />
                </a>

            </div>
            <p>כל הזכויות שמורות למלי דיין ©</p>
        </div>

    );
}
