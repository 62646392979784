import Info1 from "../images/info1.jpeg"
import React from "react";
import Guys from '../images/guys.png'
import { IoCall } from "react-icons/io5";
import { MdOutlineDone } from "react-icons/md";


export default function Info() {
    return (
        <div className="p-4 bg-brown py-8 rounded-t-3xl pb-12">


            {/* <h1 className="text-3xl md:text-4xl text-center font-extralight mt-8">ממאמן כושר לאיש עסקים </h1> */}
            <h1 dir="rtl" className="text-5xl md:text-6xl text-center text-white font-extrabold mt-2"> ייעוץ אישי</h1>
            <div dir="rtl" className="mt-12 text-white  leading-7  md:text-2xl text-center max-w-[1000px] mx-auto px-4">

                <p className="mt-8">לפעמים את מרגישה כל - כך לבד, גם אם את  בזוגיות - מרגישה תקועה, חוששת ולא יודעת איך לצאת מהתסכולים והרגשות שעולים לך. </p>
                <p className="mt-8">את רוצה זוגיות טובה ולא מצליחה באמת לייצר אחת כזו לעצמך. לא תמיד יש לך את כל התשובות. <br /> <span className="font-bold">זה הזמן שלך להגיע לייעוץ אישי ולהתחיל את השינוי שאת רוצה לעשות בחייך.</span></p>
                <p className="mt-8">במפגשים האישיים בקליניקה נעבוד על פיתוח תחושת ערך, ביטחון עצמי, גבולות <br />ומודל זוגי מוצלח לחיים.</p>


            </div>

            <div className="mt-12 flex flex-col md:flex-row-reverse max-w-[1000px]  rounded-3xl shadow-2xl mx-auto">
                <img alt='image' src={Guys} className='md:max-w-[500px] border-8 border-white shadow-2xl object-contain hover:scale-105 duration-300 mx-auto m-4 rounded-3xl' />
                <div dir='rtl' className='px-4  m-4 md:m-8 mb-8'>
                    <h1 className='text-[#e8b3a3]  md:text-right  font-extrabold text-3xl md:text-4xl mt-4'>
                        למי מתאים ייעוץ אישי?

                    </h1>

                    <ul className='mx-auto  text-right md:text-right  md:text-xl text-white mt-4'>
                        <li className='mt-4 flex items-center'>
                            <MdOutlineDone className="w-5 h-5 ml-2 md-icon" />       למי שזקוקה לשיפור היחסים עם עצמה.

                        </li>

                        <li className='mt-4 flex items-center'>
                            <MdOutlineDone className="w-5 h-5 ml-2 md-icon" />      מי שזקוקה לעלות את תחושת ביטחון העצמי והערך שלה.
                        </li>

                        <li className='mt-4 flex items-center'>
                            <MdOutlineDone className="w-5 h-5 ml-2 md-icon" />     למי שלא מצליחה ליצור זוגיות ארוכה ואוהבת.
                        </li>

                        <li className='mt-4 flex items-center'>
                            <MdOutlineDone className="w-5 h-5 ml-2 md-icon" />      למי שנמצאת בזוגיות ומרגישה תקועה.
                        </li>

                        <li className='mt-4 flex items-center'>
                            <MdOutlineDone className="w-5 h-5 ml-2 md-icon" />     למי שרוצה לעשות שינוי בחיים ולא יודעת איך.
                        </li>

                    </ul>

                </div>
            </div>

            <div className="mt-12  max-w-[800px] bg-white py-8 px-6 rounded-3xl shadow-2xl mx-auto">
                {/* <img alt='image' src={More1} className='md:max-w-[500px] border-8 border-white rounded-3xl shadow-2xl hover:scale-105 duration-300 mx-auto m-4 ' /> */}
                <div dir='rtl' className='px-4 mx-auto justify-center flex flex-col items-center m-4 md:m-8'>

                    <h1 className='text-primary  text-center font-extrabold text-3xl md:text-4xl mt-4'>
                        סדנאות

                    </h1>

                    <h1 className='text-black text-center  md:text-right md:text-xl max-w-[600px]  mt-4'>
                        סדנאות העצמה נשית ובנושאי זוגיות ואהבה
                        לימי הולדת, מפגשי נשים וסתם כי בא לכן לעשות משהו כיפי ושונה.

                    </h1>

                    <h1 className='text-primary  text-center  font-extrabold text-3xl md:text-4xl mt-8'>
                        הרצאות

                    </h1>
                    <ul className='mx-auto md:list-disc text-center md:text-right  md:text-xl text-black mt-4'>
                        <li className='mt-4 font-bold'>
                            כמה שפות יש לאהבה?
                        </li>

                        <h1 className='text-black  text-center md:text-right md:text-xl max-w-[600px]  mt-2'>
                            הרצאה שמגלה לכם מהי שפת האהבה שלכם, איך אתם אוהבים ואיזו שפת אהבה יש לבני הזוג שלכם.

                        </h1>

                        <li className='mt-4 font-bold'>
                            איך את מדברת לעצמך?
                        </li>
                        <h1 className='text-black  md:text-right md:text-xl max-w-[600px]  mt-2'>
                            כיצד הדיבור הפנימי משפיע על המחשבות, הרגשות והמעשים שלנו בחיים.
                            משינוי הדיבור פנימי להגשמת חלומות.
                        </h1>
                    </ul>

                </div>
            </div>
            <img alt="dd" src={Info1} className='md:max-w-[500px] border-8 border-white shadow-2xl  object-contain hover:scale-105 duration-300 mx-auto m-4 my-16 rounded-3xl' />

            <h1 dir="rtl" className="text-4xl md:text-5xl text-center text-[#e8b3a3] font-extrabold my-8">שינויים רואים בפעולות ולאורך זמן</h1>
            <div dir="rtl" className=" text-white  leading-7 md:text-2xl text-center max-w-[1000px] mx-auto px-4">
                <p className="mt-8"> בייעוץ הזוגי
                    באים לעבוד, ללמוד ולשנות.</p>
                {/* <p className="mt-8">כדי לחזק את הנאמר בקליניקה דרך משימות ותרגילים וכך לאט לאט מתחילים לראות את השינויים והקלה בבית, הזוגיות ובלב.</p> */}
                <p className="mt-2">המפגשים בקליניקה עוזרים לכם לעשות את השינויים הקטנים שמשנים לכם את החיים  <br />
                    ובעיקר מקלים עליכם בבית, בזוגיות ובלב.</p>


            </div>
            <h1 className="text-3xl md:text-4xl text-center text-white  font-extrabold  mt-8">אז צרו קשר </h1>
            <h1 dir="rtl" className="text-5xl md:text-6xl text-center text-[#e8b3a3] font-extrabold mt-2">וניפגש!</h1>

            <div className="flex justify-center my-12 text-white">
                <a href="tel:+972509933553" className="bg-brown text-center flex justify-center items-center from-primary m-2 border-2 shadow-2xl to-secondary px-10 py-4 text-lg font-extrabold rounded-full hover:scale-105 duration-300">
                    <IoCall className='text-4xl mr-4' />
                    <span className='text-xl md:text-2xl'>לשיחת ייעוץ בחינם</span>
                </a>
            </div>
            {/* <div className="flex justify-center">
                <a href="#messageLeadForm" className="bg-transparent border-2 mt-12 px-8 py-4 text-lg font-extrabold rounded-full hover:scale-95 duration-300"
                >שמרו לי מקום בהרצאה</a>
            </div> */}


        </div>
    );
}
