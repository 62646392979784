import React from 'react'
import Hero from "../components/Hero"
import More from '../components/More'
import Info from '../components/Info'
import Study from '../components/Study'
import MessageLeadForm from '../components/MessageLeadForm'
import Footer from '../components/Footer'
import ClientsSwiper from '../components/ClientsSwiper'

const HomePage = () => {
    return (
        <div>
            <Hero />
            <More />
            <Info />
            <ClientsSwiper
                testimonials={[
                    {
                        "text": "תודה רבה מלייי, ממש שינית לנו את הזוגיות ותודה רבה על כל המוטיבציה שנתת לנו ❤️❤️",
                        "author": ""
                    },
                    {
                        "text": "מלי היקרה תודה רבה על כל התהליך בהחלט עשית לנו שינוי ענק ושיפרת את הזוגיות שלנו מאוד... תודה רבה רבה ומעריכים מאוד ואוהבים מאוד ❤️",
                        "author": ""
                    },
                    {
                        "text": 'היי מלי, הגענו אלייך כדי "לנער את הסדינים", להתייחס לדברים שמפריעים לנו אחד בשנייה בנוגע לזוגיות ולהורות שלנו. בטיפול פתחנו בפנייך נושאים שמעכבים ומורידים אותנו ולמדנו איך אפשר לקבל, להתמודד, להודות, לוותר ולא להתפשר על מה שצריך. המפגשים השפיעו על ההתנהלות בבית  עם הילדים, הצבת גבולות והכי חשוב שכדי לקבל זכויות עליהם ללמוד שיש חובות. הבנות לוקחות חלק פעיל בבית ואני למדתי לשחרר בעיקר בחדרים שלהן ולתת לעצמי יותר זמן של שקט ורוגע בעיקר כשבני מגיע הביתה. אני גם למדתי לשחרר בכל ענייני המשפחה של בני ולא לייצר "דרמות" מיותרות שלא מוסיפות כלום... והכי חשוב שהזכרנו אחד לשנייה למה אנחנו אוהבים וכמה ❤️',
                        "author": ""
                    },

                    {
                        "text": 'גם אני מודה לך מאוד מלי היקרה. נראה ששמת את האור בדיוק במקומות החשוכים שהיינו בהם ועכשיו אנחנו מרגישים הרבה יותר פתוחים לתת ולקבל אהבה וכמובן גם הביטחון עלה. אישית אני רוצה לומר שלמדתי המון בעיקר על עצמי.. אומרים שכדי להבין על עצמך - פשוט תשאל את עצמך.. ובמעמד מולך ומול קטי הצלחתי לגייס מספיק פתיחות כדי לומר את מה שהיה לי בלב. הצלחתי לפקוח את העניים על המקומות שלא התייחסתי אליהם . ובצלחתי להבין מעצמי על עצמי מה אני רוצה משפר ומשמר בתוך הזוגיות שלי בתור צד בה. תודה לך מלי על העמדה שלך כלפינו. תודה שעשית לנו טוב. ותודה לאישתי היקרה על עצם היותך. ועל שאמרת לי "כן" לפני כמעט שנתיים🙏🏽❤️',
                        "author": ""
                    },


                    {
                        "text": 'מלי יקרה, תודה רבה על שהיית שם בשבילנו עם כלכך הרבה רגישות ולב גדול ופתוח.🤍',
                        "author": ""
                    },


                    {
                        "text": 'היי מלי, אני אומרת כמעט כל יום שאשלח לך הודעה. מאז שהסברת לבעלי על הילדה, ועל זה שאם הוא יגיד שהיא ילדה בעייתית והשניה ילדה טובה , הוא השתנה פלאים. אמאלה כמה הם מסתדרים עכשיו יש לי אוויר אני יכולה ללכת למקומות מבלי לחשוש, הוא איתה בכיף ובשמחה, וגם היא! אז תודה לך. זה שווה עולם🩷😘',
                        "author": ""
                    },


                    {
                        "text": 'מלי היקרה שלנו🫶🏼 מהרגע שהגענו אלייך, ביחד או בנפרד לא נתת לנו להרגיש לבד אף פעם🙏🏼 היית האוזן הקשבת, המדריכה שלנו. נכנסו אבודים ויצאנו ממך חזקים ושלמים💗 אנחנו מודים לך על כל רגע שהיית שם בשבילינו, וכמובן זה לא הודעת פרידה כי אנחנו עוד נהיה בקשר ובעזרת השם עוד נתראה אבל יותר חזקים ממה שהגענו אלייך בפעם הראשונה🙏🏼',
                        "author": ""
                    },


                    {
                        "text": 'מלי דבר ראשון את אלופה מעריך ממש ובאמת תודה על הכל אין ספק שזה תהליך שעוברים ואת האמת אני מרגיש במקום אחר',
                        "author": ""
                    },


                    {
                        "text": 'מלי יקרה שלנו אחרי שהתלבטנו המון לאן ללכת לטפל והאם לטפל אני שמחה שהגענו אליך! תודה שעזרת לנו, תודה על התמיכה והאוזן הקשבת! 🩷🩷',
                        "author": ""
                    },

                    {
                        "text": 'תודה מלי אין ספק שהפגישות שלנו מציבות לנו את ההתמודדויות שלנו ביומיום כמו מראה, היינו זקוקים לכך. אני לוקחת איתי כל דבר שנאמר ומסתכלת קדימה!!! אני יוצאת מאד אופטימית מהמפגשין ומלאה מוטיבציה לשפר את הזוגיות. תודה❤️❤️',
                        "author": ""
                    },





                ]
                }
            />
            <Study />
            {/* <MessageLeadForm /> */}
            <Footer />
        </div>
    )
}

export default HomePage